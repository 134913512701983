:root {
    --color-pink: #ee415f;
    --color-green: #10b981; 
    --color-orange: #fc7d4a;
    --color-red: #d14758;
    --color-pale-pink: #ef6e85;
    --color-coral: #f3907e;
    --color-peach: #ecb38d;
    --color-aqua: #a0ded0;
    --color-pale-aqua: #c0ebe1;
    --color-lightest-blue: #f8fcff;
    --color-blue: #4c88e9;
    --color-black: #242e39;
    --color-gray-1: #3b424b;
    --color-gray-2: #68707d;
    --color-gray-3: #949fab;
    --color-gray-4: #c7cdd4;
    --color-gray-5: #edf1f6;
    --color-gray-6: #f7f9fb;
    --color-white: #fff;
}

.s-border {
    /* @apply border-gray-300 rounded-md shadow-sm; */
    border: 1px solid #ccc; /* Example border */
    @apply rounded-md;
  }

.main-container {
    margin-top: 3.15rem; /* Adjust the margin as needed */
    margin-bottom: 1.2rem;
    height: calc(100vh - 4.35rem); /* Adjust based on the navbar's height if needed */
    margin-right: 1.5rem; /* Right margin */
    margin-left: 1.5rem; /* Left margin */
    display: flex;
    flex-direction: column;
  }

  /* display: flex;
    align-items: flex-end; Align items to the bottom */
  
.ribbon-menu {
      box-sizing: border-box;
      width: 100%;
      padding: 0.5rem;
      margin: 0.75rem 0;
      background-color: white;
      height: 100px;
}
  
.ribbon-container.with-right {
      display: grid;
      grid-template-columns: 5fr 3fr; /* Maintains the original 5:3 ratio */
      gap: 1.2rem;
      height: 100%;
}
  
.ribbon-column {
    display: grid;
    grid-template-rows: 1fr 1fr; /* Divides each column into two equal rows */
    gap: 0.5rem; /* Adjust the gap between rows as needed */
    padding: 0px 0.5rem;
}
  
.ribbon-row {
    display: flex;
    justify-content: space-between; /* Pushes child elements to opposite ends */
    align-items: center; /* Align items vertically */
}

.ribbon-row .collection-dropdown {
  width: 100%;
}

.button-group-left {
    display: flex;
    gap: 10px; /* Adjusts spacing between buttons in the group */
}
  
.button-group-right {
  display: flex;
  gap: 10px; /* Adjusts spacing between buttons in the group */
}

.core-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex-grow: 1; /* Allow it to grow as needed */
  flex-shrink: 1; /* Allow it to shrink as needed */
  flex-basis: auto; /* Default basis */
  margin: 0;
  padding: 0 2px; /* Adjust padding to align with the design */
  background-color: var(--color-white);
  color: var(--color-gray-2);
}

.core-menu-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 32px; */
  margin: 0;
  border: 0;
  background: transparent;
  color: currentColor;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  
  /* Set padding and width as needed, perhaps like so: */
  padding: 4px; /* Adjust as necessary */
  width: auto; /* Or set a specific width */
}


.core-menu-btn:hover,
.mcore-menu-btn.is-active {
  background-color: var(--color-gray-5);
  color: var(--color-black);
}

.panel-container {
  display: grid;
  gap: 1.2rem;
  height: 100%;
}

/* Layout with both panels */
.panel-container.with-right {
    grid-template-columns: 5fr 3fr; /* Original 2:1 ratio */
}
  
/* Adjusted layout for centering the left panel without expanding its width */
.panel-container.without-right {
    display: grid;
    grid-template-columns: 3fr 10fr 3fr; /* Center column maintains the left panel width */
}

  
.left-panel,
.right-panel {
    flex: 1; 
    padding: 1rem;
    overflow-y: auto; /* If you want the panels to be scrollable */
    scrollbar-width: thin; /* "auto", "thin", or "none" */
    scrollbar-color: #ccc #f1f1f1; /* Thumb color and track color */
    @apply bg-white;
    position: relative; /* Sets position context for inner-panel */
}

.left-panel.centered {
    grid-column: 2 / 3; /* Place the left panel in the center column */
}

.inner-panel {
  font-family: 'Lora', serif;
  font-size: 1rem;
  /* resize: none; */
 
  position: absolute; /* Absolute positioning within left-panel */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem; /* Matches padding of left-panel for consistency */
  overflow-y: auto; /* Allows scroll within itself */
  word-wrap: break-word; /* Ensures long words don’t cause horizontal overflow */
  box-sizing: border-box; /* Includes padding in height calculation */
  max-height: 100%;

  /* Re-apply scrollbar styling to match other panels */
  scrollbar-width: thin; 
  scrollbar-color: #ccc #f1f1f1; 
}

  /* overflow-y: scroll; */
  /* border: 1px solid #ccc; */

/* Add more specific styles for your panels here */  
.button-toggle {
  display: flex;
  border: solid #ccc; 
}

/* Example Media Query for Responsive Adjustment */
@media (max-width: 768px) {
    .panel-container {
      flex-direction: column;
    }
}

@media (min-width: 1600px) {
  .main-container {
      max-width:1600px;
      margin-right: auto; /* Center the container by setting right margin to auto */
      margin-left: auto;
  }

}