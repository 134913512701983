/* @tailwind base;
@tailwind components;
@tailwind utilities; */
/* old green:  #58ce97 */
:root {
    --color-pink: #ee415f;
    --color-green: #10b981; 
    --color-orange: #fc7d4a;
    --color-red: #d14758;
    --color-pale-pink: #ef6e85;
    --color-coral: #f3907e;
    --color-peach: #ecb38d;
    --color-aqua: #a0ded0;
    --color-pale-aqua: #c0ebe1;
    --color-lightest-blue: #f8fcff;
    --color-blue: #4c88e9;
    --color-black: #242e39;
    --color-gray-1: #3b424b;
    --color-gray-2: #68707d;
    --color-gray-3: #949fab;
    --color-gray-4: #c7cdd4;
    --color-gray-5: #edf1f6;
    --color-gray-6: #f7f9fb;
    --color-white: #fff;
  }
  
  html {
    box-sizing: border-box;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  body {
    color: var(--color-black);
  
    font-size: 16px;
    line-height: 1.5;
  
    /* font-family: sans-serif; */
    line-height: 1.6;
  }
  
  a {
    color: var(--secondary-color);
  }
  
  a:hover {
    cursor: pointer;
  }
  
  p {
    margin: 0;
  }
  
  p + p {
    margin-top: 16px;
  }
  
  kbd,
  code {
    padding: 4px;
  
    border-radius: 4px;
    background-color: var(--color-gray-6);
    color: var(--color-gray-1);
  
    box-decoration-break: clone;
  
    font-size: 14px;
  }
  
  /* This is redundant? */
  .app {
    /* width: 720px; */
    /* max-height: 500px;*/
    height: 500px;
    overflow-y: auto;
    /* margin: 0 auto 48px; */
    border: 1px solid var(--color-gray-4);
  }


  
  .editor {
    position: relative;
    width: 100%;
    /* margin-bottom: 48px; */
   
    /* border-radius: 4px; */
    /* padding: 8px; */
    padding: 0.5rem;
    
  }

  .control-menu {
    position: absolute;
    bottom: 0;
    z-index: 1;
  
    display: flex;
    align-items: flex-end; /* Align items to the bottom */

    width: calc(100% - 4px);
    height: 40px;
    margin: 0;

    background-color: var(--color-white);
    color: var(--color-gray-2);
  }
  
  .grid-cell-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start ; /* space-between Aligns children to start and end */
    height: 100%; /* Full height of the grid cell */
  }

  .editor-menu {
    position: sticky;
    top: 0px; 
    left: 2px;
    z-index: 1;
  
    display: flex;
    align-items: center;
    gap: 8px;
  
    width: calc(100% - 4px);
    height: 40px;
    margin: 0;
    padding: 0 8px;
  
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid var(--color-gray-5);
    background-color: var(--color-white);
    color: var(--color-gray-2);
  }

  .relative-container {
    position: relative;

  }
  
  .bubble-menu-dark,
  .bubble-menu-light {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 4px;
  
  }
  
  .bubble-menu-dark-hidden,
  .bubble-menu-light-hidden {
    display: none; 
  }

  .bubble-menu-dark {
    background-color: var(--color-gray-1);
    color: var(--color-white);
  }
  
  .bubble-menu-light {
    border: 2px solid var(--color-gray-4);
    background-color: var(--color-white);
    color: var(--color-gray-2);
  }
  
  .link-menu {
    display: flex;
    align-items: center;
    gap: 8px;
  
    /* height: 40px; */
    padding: 8px;
  
    border: 2px solid var(--color-gray-5);
    border-radius: 4px;
    background-color: var(--color-white);
  }
  
  .button,
  /* .button-approve,   */
  /* .button-clear, */
  /* .button-remove, */
  .menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
  
    height: 32px;
  
    margin: 0;
    padding: 0 32px;
  
    border: 0;
    /* border-radius: 4px; */
    background: transparent;
    color: currentColor;
  
    /* font-family: inherit; */
    font-size: 0.8rem;;
    line-height: 1;
    font-weight: inherit;
  
    white-space: nowrap;
    cursor: pointer;
  }
  
  .menu-button {
    width: 32px;
    padding: 0;
  }
  
  .button:hover,
  .button.is-active,
  .menu-button:hover,
  .menu-button.is-active {
    background-color: var(--color-gray-5);
    color: var(--color-black);
  }

  .bubble-menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
  
    height: 32px;
    width: 32px;
  
    margin: 0;
    padding: 0;
    /* padding: 0 32px; */
  
    border: 0;
    /* border-radius: 4px; */
    background: transparent;
    color: currentColor;
  
    /* font-family: inherit; */
    font-size: 0.8rem;;
    line-height: 1;
    font-weight: inherit;
  
    white-space: nowrap;
    cursor: pointer;
  }
  
  .bubble-menu-button:hover,
  .bubble-menu-button.is-active {
    background-color: var(--color-gray-5);
    color: var(--color-black);
  }


  .button:disabled,
  .menu-button:disabled {
    color: var(--color-gray-4);
  }
  
  .button-approve {
    @apply bg-emerald-500 text-white; 
    /* border-solid hover:border hover:border-gray-400; */
  }

  .button-clear {
    background-color: var(--color-gray-3);
    color: var(--color-white);
  }
  
  .button-remove {
    /* background-color: var(--color-red);
    color: var(--color-white); */
    @apply bg-red-700 text-white 
    /* border-solid hover:border hover:border-gray-400; */
  }
  
  .button-remove:disabled {
    background-color: var(--color-pale-pink);
  }
  
  /* Basic editor styles */
  .ProseMirror {
    margin-top: 4px;
    padding: 20px 1rem 1rem; /* used to be 48 top 8 8 */
    font-size: 1rem;
  
    /* border: 2px solid var(--color-gray-4);
    border-radius: 4px; */
  }
  
  .ProseMirror:focus {
    margin-top: 4px;
    padding: 20px 1rem 1rem; /* used to be 48 top */
  
    /* border: 2px solid var(--color-gray-4);
    border-radius: 4px; */
  }
  
  .editor-mini .ProseMirror {
    padding-top: 8px;
  }
  
  .ProseMirror-focused {
    border-color: var(--color-black);
    outline: none;
  }
  
  .ProseMirror > * + * {
    margin-top: 16px;
  }
  
/* Modals */
  .modal-title {
    /* @apply text-base font-semibold leading-6 text-gray-900; */
    margin: 0 0 24px;
    font-size: 20px;
    line-height: 1;
    
  }
  .modal-textarea {
    font-size: 0.875rem;
    line-height: 1.5rem;
    
    margin-top: 0.5rem;
    display: block;
    width: 100%;
    border-width: 0px;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.75rem;
    padding-right: 2.5rem;
  }

  .modal-close {
    position: absolute;
    top: 8px;
    right: 8px;
  
    display: flex;
    align-items: center;
    justify-content: center;
  
    width: 32px;
    height: 32px;
  
    border: 0;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
  }
  
  .modal-close:hover {
    background-color: var(--color-gray-5);
    color: var(--color-black);
  }
  
  .modal-input {
    width: 100%;
    height: 40px;
  
    margin-bottom: 16px;
  
    border: 2px solid var(--color-gray-5);
    border-radius: 4px;
  
    font-size: inherit;
  }
  
  .modal-btn-bar {
    @apply bg-slate-100 px-4 py-4 sm:flex sm:flex-row-reverse sm:px-6;
  }

  .modal-buttons {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }
  
  .modal-picker-outer {
      overflow-y: scroll;
      resize: none;
      height: 300px;
      border: 1px solid #ccc;
      
      word-wrap: break-word; /* Ensures long words don’t cause horizontal overflow */
  }

  .modal-picker-element {
    position: relative;
    background:  #fffdfae2 ; /* Almost White */
    font-size: 0.9rem;
    /* color: #333; */
    color: var(--color-black);
    border: #ccc solid 1px;
    /* font-family: 'Lora', serif */
}

.modal-picker-element .tools {
  position:absolute;
  bottom:0;
  right:0;
}