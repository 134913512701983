@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import '@fontsource/roboto/index.css';
@import '@fontsource/noto-sans/index.css';

/* @import url('https://fonts.googleapis.com/css2?family=Rubik+Wet+Paint&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');

/* Global Styles */
:root {
  --primary-color: #17a2b8;
  --dark-color: #343a40;
  --light-color:  #e2e8f0; 
  /* #f4f4f4; */
  --danger-color: #dc3545;
  --success-color: #10b981; 
  --secondary-color: #a93af9;

}

body {
  /* font-family: 'Roboto', sans-serif; */
  @apply font-sans;

  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  /* #fff; */
  color: #333;
}

.calendly-inline-widget iframe {
  height: 700px !important;
  }


a {
  color: var(--primary-color);
  text-decoration: none;
}

ul {
  list-style: none;
}

input:focus {
  outline: none;
}


/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}

/* Font styling */
/* The default is currently Arial - for historic reasons - these are specific styles for functioal separation */
/* The @apply font-sans sets the font family (Roboto or whatever, as per the tailwind.config), not the weight.
By default, the weight is font-normal (400).
Use Tailwind's font weight classes (font-bold, font-light, etc.) to specify the desired weight: */

/* font-thin: 100
font-extralight: 200
font-light: 300
font-normal: 400
font-medium: 500
font-semibold: 600
font-bold: 700
font-extrabold: 800
font-black: 900 */

.font-quiet {
  font-size: 0.8rem;
  color: darkviolet;
}

.font-input {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-output {
  font-family: 'Lora', serif;
}

.font-special {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 900;
  font-style: normal;
}

.highlighted {
  background-color: rgb(255, 222, 101);
}

.sunset {
  @apply bg-gradient-to-br from-purple-700 to-purple-300;
}

/*utils*/
.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}


.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-secondary,
.alert-secondary {
  /* background: var(--secondary-color);
  color: #fff; */
  @apply text-white bg-purple-600
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  /* background: var(--light-color); */
  @apply bg-amber-200;
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  /* background: var(--dark-color); */
  @apply bg-slate-900;
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-plain{
  display: inline-block;
  border: none;
  background: white;
  color:  var(--secondary-color);
  cursor: pointer;
}

.btn-small{
  /* display: inline-block; */
  display: flex; /* Use flex instead of inline-block */
  align-items: center; /* Align items vertically in the center */
  justify-content: center; /* Center the content horizontally */


  padding: 0.2rem 0.6rem 0.2rem 0.4rem;
  font-size: 0.8rem;
  /* border: none; */
  cursor: pointer;
  /* margin-top: 0.5rem; */
  transition: opacity 0.2s ease-in;
  outline: none;
  @apply rounded-sm
  
}

.btn-medium {
  display: flex; /* Use flex instead of inline-block */
  align-items: center; /* Align items vertically in the center */
  justify-content: center; /* Center the content horizontally */

  height: 36px;
  
  margin: 0;
  padding: 0.5rem 2rem;
  white-space: nowrap;

  font-size: 0.8rem;
  
  
  /* line-height: 1; */

  cursor: pointer;
  transition: opacity 0.2s ease-in;
  outline: none;
  
  @apply rounded-sm drop-shadow-md

}


/* Utilities */
.container {
  max-width: 90vw;
  margin: auto;
  overflow: hidden;
  padding: 1rem 2rem;
  margin-top: 5rem;
  margin-bottom: 2rem;
  background: white; 
  /* #f8fafc; */
}

.narrow_container {
  max-width: 60vw;;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}

.home_container {
  width: 100%;
  margin-right: auto;
  margin-left: auto
}

@media (min-width: 640px) {
  .home_container {
      max-width:640px
  }
}

@media (min-width: 768px) {
  .home_container {
      max-width:768px
  }
}

@media (min-width: 1024px) {
  .home_container {
      max-width:1024px
  }
}

@media (min-width: 1280px) {
  .home_container {
      max-width:1280px
  }
}

::selection {
  background: rgb(255, 236, 161);
}

.surround {
  @apply rounded-md border-0 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-gray-200;

}

.semi-surround {
  border-top: 1px solid #ccc; /* 1px solid top border */
  border-right: 1px solid #ccc; /* 1px solid right border */
  border-left: 1px solid #ccc; /* 1px solid left border */
  border-bottom: none; /* No bottom border */
}

/* Gist Page */

.gist-panel-container {
  display: grid;
  gap: 1.2rem;
  height: calc(100% - 2rem);
  /* margin-top: 1rem; */
  margin: 1rem 0;
}


/* Layout with both panels */
.gist-panel-container.with-right {
  grid-template-columns: 5fr 3fr; /* Original 2:1 ratio */
}

.gist-left-panel {
    flex: 1; 
    overflow-y: auto; /* If you want the panels to be scrollable */
    scrollbar-width: thin; /* "auto", "thin", or "none" */
    scrollbar-color: #ccc #f1f1f1; /* Thumb color and track color */
    @apply bg-white;
    position: relative; /* Sets position context for inner-panel */
}

.gist-right-panel {
  flex: auto; 
  overflow-y: auto; /* If you want the panels to be scrollable */
  scrollbar-width: thin; /* "auto", "thin", or "none" */
  scrollbar-color: #ccc #f1f1f1; /* Thumb color and track color */
  @apply bg-white;
  position: relative; /* Sets position context for inner-panel */
  align-self: start;
}

.gist-panel-head {
  /* background-color: #fffdfae2 ; Almost White */
  font-size: 1.3rem;
  font-weight: 700;
  padding: 1rem;
  @apply text-stone-600 bg-purple-50;
}
.gist-editor {
  position: relative;
  width: 100%;
  padding: 0.5rem 1.5rem;
  
}

/* Clipper Page */
.ribbon-title {
  font-family: 'Red Hat Display', sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  @apply text-transparent bg-clip-text bg-gradient-to-br from-purple-700 to-purple-400;
  /* text-xl font-semibold leading-6  */
}

.help-label {
  @apply text-sm font-semibold leading-6 text-zinc-500;
}
/* Collections */

.section-title {
  font-family: 'Red Hat Display', sans-serif;
  font-size: 1.75rem;
  font-weight: 900;
  @apply text-transparent bg-clip-text bg-gradient-to-br from-purple-700 to-purple-400;
  /* text-xl font-semibold leading-6  */
}
.collection-container {
  font-family: inherit;
  margin-bottom: 2rem;
  background: white;
  /* border: 1px solid #333; */
  padding: 1rem 1.5rem ;
}

/* Collections Page */
.collection-title {
  /* font-family: 'Red Hat Display', sans-serif; */
  font-size: 1.3rem;
  font-weight: 700;
  cursor: pointer;
  @apply text-stone-600 ;
}

.collection-title:hover {
  /* color: var(--secondary-color);  */
  @apply text-purple-500;
}

.collection-size {
  font-size: 0.8rem;
  @apply text-gray-700
}

.collection-size-number {
  font-size: 1.25rem;
  font-weight: 700;
  @apply text-fuchsia-900
}

.collection-size-item {
  font-size: 0.8rem;
  font-weight: 700;
  @apply text-fuchsia-900
}

.collection-update {
  font-size: 0.8rem;
  font-weight: 700;
  @apply text-fuchsia-900
}

/* Single Collection Page */
.single-collection-size {
  font-size: 1rem;
  @apply text-gray-700
}

.single-collection-size-number {
  font-size: 1.5rem;
  font-weight: 700;
  @apply text-fuchsia-900
}

.single-collection-size-item {
  font-size: 1rem;
  font-weight: 700;
  @apply text-fuchsia-900
}

.single-collection-update {
  font-size: 1rem;
  font-weight: 700;
  @apply text-fuchsia-900
}



/* Draft Area*/
.edit-toggle {
  @apply relative inline-flex h-6 w-11 flex-shrink-0 
  cursor-pointer rounded-full border-2 border-transparent 
  transition-colors duration-200 ease-in-out 
  focus:outline-none focus:ring-1 focus:ring-purple-200 focus:ring-offset-2
  
}

.edit-toggle-inside {
  @apply pointer-events-none inline-block h-5 w-5 
  transform rounded-full bg-white 
  shadow ring-0 
  transition duration-200 ease-in-out
}

/* Editor */

.editor {
  
  font-size: 1.1rem;
  resize: none;
  height: 500px;
}

.editor-small {
  
  font-size: 1.1rem;
  resize: none;
  height: 100px;
}

.editor .editor-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.editor input[type='text'] {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: inherit;
  border: none;
  font-family: inherit;
  overflow-wrap: break-word; 
}


.editor-textarea {
  display: block;
  width: 100%;
  height: 500px;
  padding: 1rem;;
  font-size: inherit;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  box-sizing: border-box;
}

.editor input[type='text']:focus {
  outline: none;
  background: none;
}

/* Forms */
.form .form-group {
  margin: 1.2rem 0;
  
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form input[type='url'],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  justify-self: center;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  background-color: white;

}


textarea:focus,
.form input[type='text']:focus,
.form input[type='email']:focus,
.form input[type='password']:focus,
.form input[type='date']:focus,
.form input[type='url']:focus,
.form select:focus,
.form input[type='email']:focus {
  outline: none;
  background-color: white;
}

.dropdown-label {
  @apply block text-sm font-semibold leading-6 text-zinc-600;
}

.dropdown-item {
  @apply mt-2 block w-full border-0 py-1.5 pl-3 
  pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  
  focus:outline-none focus:ring-1 focus:ring-gray-300 focus:ring-offset-2
  sm:text-sm sm:leading-6;
  /* font-size: 18px; */
  /* @apply font-output */
  /* focus:ring-2 focus:ring-gray-200 */
}

/* Menus */
.menu {
  display: flex;
  text-align: left;
  flex-direction: column;
  border: solid 1px lightgray;
  background-color: white;
  padding: 2rem;
  font-family: inherit;
  font-size: 0.8rem;
  max-width: 200px;
 
}


.menu input[type='text'] {
  font-size: 0.8rem;
  margin: 0.3rem 0;
  padding: 0.2rem;
  outline: none;
  
}

/* Summary Page */
.summary {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 2rem;
  align-items: top;
}

.summary-textarea {
  overflow-y: scroll;
  resize: none;
  border: 1px solid #ccc;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.summary-block {
  font-family: 'Lora', serif;
  font-size: 1rem;
  overflow-y: scroll;
  resize: none;
  height: 500px;
  /* height: 100%; */
  border: 1px solid #ccc;
  scrollbar-width: thin; /* "auto", "thin", or "none" */
  scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
  word-wrap: break-word; /* Ensures long words don’t cause horizontal overflow */
}

.summary-sent {
  position: relative; 
  background: #fffdfae2; /* #fffaf0;*/
  color: #333;
  border: #ccc solid 1px;
  font-family: 'Lora', serif;
  @apply rounded-sm;
  
}

@keyframes cementColor {
  from {
      background-color: #fffaf0 ; /* Light yellow */
  } 
  to {
      background-color: #ffffff ; /* White */
  }
}

.summary-sent-white {
  position: relative;
  /* background: #fffdfa; */
  background-color: #fffdfae2 ; /* Almost White */
  color: #333;
  border: #ccc solid 1px;
  font-family: 'Lora', serif;
  @apply rounded-sm;
  /* animation: cementColor 1s forwards; */
}

.summary-sent-blue {
  position: relative;
  background: #f0f7ff;
  color: #333;
  border: #ccc solid 1px;
  font-family: 'Lora', serif
}

.summary-sent-red {
  position: relative;
  background: #fff0f8;
  color: #333;
  border: #ccc solid 1px;
  font-family: 'Lora', serif
}

.summary-sent .tools, .summary-sent-white .tools{
    /* display: inline-flex; 
    justify-content: right; */
    
    position:absolute;
    bottom:0;
    right:0;
    display: flex; /* Use flexbox to align children in a row */
    /* gap: 5px; Add a gap between the buttons */
}

.tool-btn {
  background-color: transparent;
  border: none;
  color: black;
  opacity: 0.6;
  cursor: pointer;
  font-size: 1rem;
  margin: 0 0.5rem 0.2rem 0;
}

.tooltip {
  font-family: 'Inter', sans-serif;
  position: absolute;
  z-index: 2; /* just above the default*/
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  background-color: gray;
  font-size: 0.8rem;
  color: white;
  padding: 5px;
  border-radius: 4px;
  margin-top: 5px; /* Adjust the space between the tooltip and the button*/
  white-space: nowrap; /* Prevents the text from wrapping */
}

.fetch-title {
  font-family: 'Red Hat Display', sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  @apply text-transparent bg-clip-text bg-gradient-to-br from-purple-800 to-purple-400;
}

.modal-textarea {
  scrollbar-width: thin; /* "auto", "thin", or "none" */
  scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
  word-wrap: break-word; /* Ensures long words don’t cause horizontal overflow */
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 0;
  /* opacity: 0.9; */
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: grey;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  /* color: var(--secondary-color); */
  @apply text-purple-500 
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

.navbar .active-link {
  @apply text-purple-400;
  font-weight: 600;
}

