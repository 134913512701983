:root {
    --pink-bg: #faf0f1; 
    --blush-bg: #fff0e3;
    --magnolia-bg:#f6efdf;
    --cream-bg: #fdf6e8;
    --purp-bg:  #faf3fd;
}
.home-container {
    max-width: 85vw;
    /* min-height: 30vw; */
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
}

.image-wrapper {
    display: flex;
    justify-content: center; /* Horizontally center content */
    align-items: center;     /* Vertically center content */
    width: 100%;             /* Fill the available width from home-container */
    flex-grow: 1;            /* Allow it to expand to fill available vertical space */

}

/* Style for the image itself */
.top-image-style {
    max-width: 70vw; /* calc(70vw - 60px); */
    height: auto;                  /* Maintain aspect ratio */
    display: block;                /* Ensures the image is block-level */
    border-radius: inherit;        /* Inherits border-radius from parent */
    object-position: center;       /* Centers the image within its frame */
    object-fit: cover;             /* Covers the area of the container without distortion */
    image-rendering: auto;         /* Allows the browser to optimize image rendering */
}


.center {
    /* display: flex ;    
    align-items: center;    */
    @apply flex flex-col justify-center items-center;
}

.bullet-list {
    list-style-type: disc; /* Default bullet points */
    padding-left: 20px; /* Example padding, adjust as needed */
  }


.setbacks-section {
    background-color: var(--pink-bg);
}

.feature1 {
    background-color: var(--blush-bg);
}

.feature2 {
    background-color: var(--magnolia-bg);
}

.feature3 {
    background-color: var(--purp-bg);
}

.full-split {
    display: grid;
    grid-template-columns: 1fr;    
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.half-split {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.two-thirds-left-split {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.two-thirds-right-split {
    display: grid;
    grid-template-columns: 1fr 2fr;
}
.third-split {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
}


.background-image-align {
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

}

.app-image-align {
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
}

.image-size-control {
    max-height: 500px;
    width:auto;
    display: flex;          /* Use flexbox for alignment */
    justify-content: flex-end; /* Center the image horizontally */
    align-items: center;     /* Center the image vertically */

}

.top-layer-image {
    max-height: 100%;       /* Image's height should not exceed the container's height */
    width: 80%;            /* Width adjusts to maintain aspect ratio */
    object-fit: contain;    /* Ensures the image is contained within its element, preserving aspect ratio */
}
.calendly-inline-widget iframe {
    height: 700px !important;
    }